import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import OrderTracking from "./pages/OrderTracking/OrderTracking";
import SohReport from "./pages/SohReport/SohReport";
import LorReport from "./pages/LorReport/LorReport";
import PaymentReport from "./pages/PaymentReport/PaymentReport";
import SalesReport from "./pages/SalesReport/SalesReport";
import Login from "./pages/Auth/Login";
import DefaultLayout from "./layouts/DefaultLayout";
import ProtectedLayout from "./layouts/ProtectedLayout";
import './App.scss';
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import ReportList from "./pages/ReportList/ReportList";
import GSTRReport from "./pages/GSTRReport/GSTRReport";
import RtoReport from "pages/RTOReport/RTOReport";
import ReturnsReport from "pages/ReturnExchangeReport/ReturnsReport";
import ExchangesReport from "pages/ReturnExchangeReport/ExchangesReport";
import FbvStockChargesReport from "pages/FbvStockChargesReport/FbvStockChargesReport";
import IssueTracking from "pages/IssueTracking/IssueTracking";
import Issue from "pages/IssueTracking/Issue";
import ProductCatalogue from "pages/ProductCatalogue/ProductCatalogue";
import RequestTracking from "pages/RequestTracking/RequestTracking";
import MRPLabel from "pages/MRPLabel/MRPLabel";

function App() {

  return (
    <>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/reports" element={<ReportList />} />
          <Route path="/order-tracking" element={<OrderTracking />} />
          <Route path="/soh-report" element={<SohReport />} />
          <Route path="/sales-report" element={<SalesReport />} />
          <Route path="/lor-report" element={<LorReport />} />
          <Route path="/return-exchange-report/returns" element={<ReturnsReport  />} />
          <Route path="/return-exchange-report/exchanges" element={<ExchangesReport />} />
          <Route path="/rto-report" element={<RtoReport />} />
          <Route path="/payment-report" element={<PaymentReport />} />
          <Route path="/gstr-report" element={<GSTRReport />} />
          <Route path="/fbv-storage-charges-report" element={<FbvStockChargesReport />} />
          <Route path="/mrp-label" element={<MRPLabel />} />
          <Route path="/issue-tracking" element={<IssueTracking />} />
          <Route path="/issue-tracking/:id/show" element={<Issue />} />
          <Route path="/product-catalogue" element={<ProductCatalogue />} />
          <Route path="/request-tracking" element={<RequestTracking />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
