import { sohReportApi } from 'slices/sohReportSlice';
// filtersSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";

export type ProductCatalogueStatusType = 'all' | 'active' | 'draft';

const today = new Date();

type FbvWarehouseHash = {
  id: number,
  name: string,
  badge_url: string,
}
interface FiltersState {
  startDateFilter: Date;
  endDateFilter: Date;
  fbvWarehouseFilter: FbvWarehouseHash[];
  orderReportOrderCreatedAtStartDateFilter: Date;
  orderReportOrderCreatedAtEndDateFilter: Date;
  orderReportStatusFilter: string;
  orderReportPaymentTypeFilter: string;
  orderReportProductNameFilter: string;
  orderReportProductIdFilter: string;
  orderReportOrderNoFilter: string;
  orderReportAwbNoFilter: string;
  orderReportDelayedShipmentFilter: boolean;
  orderReportDelayedDeliveryFilter: boolean;
  orderReportFbvEnabledFilter: boolean;
  orderReportFbvWarehouseFilter: string[];
  sohReportProductNameFilter: string;
  sohReportProductIdFilter: string;
  sohReportInclude0RosFilter: boolean;
  sohReportFbvEnabledFilter: boolean;
  sohReportFbvWarehouseFilter: string[];
  salesReportOrderCreatedStartDateFilter: Date;
  salesReportOrderCreatedEndDateFilter: Date;
  salesReportCategoryFilter: string;
  salesReportProductIdFilter: string;
  salesReportProductTitleFilter: string;
  salesReportFbvEnabledFilter: boolean;
  salesReportFbvWarehouseFilter: string[];
  lorReportSelectDaysFilter: string;
  lorReportProductNameFilter: string;
  lorReportProductIdFilter: string;
  lorReportFbvEnabledFilter: boolean;
  lorReportFbvWarehouseFilter: string[];
  lorReportShowHistoricalDataFilter: boolean;
  returnsReportReturnedAtStartDateFilter: Date | undefined;
  returnsReportReturnedAtEndDateFilter: Date | undefined;
  returnsReportReceivedAtStartDateFilter: Date | undefined;
  returnsReportReceivedAtEndDateFilter: Date | undefined;
  returnsReportManufactureErrorFilter: string;
  returnsReportOrderNumberFilter: string;
  returnsReportSKUFilter: string;
  returnsReportReasonsFilter: string[];
  returnsReportTrackingNumberFilter: string;
  returnsReportFbvEnabledFilter: boolean;
  returnsReportFbvWarehouseFilter: string[];
  exchangesReportExchangedAtStartDateFilter: Date | undefined;
  exchangesReportExchangedAtEndDateFilter: Date | undefined;
  exchangesReportReceivedAtStartDateFilter: Date | undefined;
  exchangesReportReceivedAtEndDateFilter: Date | undefined;
  exchangesReportManufactureErrorFilter: string;
  exchangesReportOrderNumberFilter: string;
  exchangesReportSKUFilter: string;
  exchangesReportReasonsFilter: string[];
  exchangesReportTrackingNumberFilter: string;
  exchangesReportFbvEnabledFilter: boolean;
  exchangesReportFbvWarehouseFilter: string[];
  rtoReportOrderedAtStartDateFilter: Date | undefined;
  rtoReportOrderedAtEndDateFilter: Date | undefined;
  rtoReportDeliveredAtStartDateFilter: Date | undefined;
  rtoReportDeliveredAtEndDateFilter: Date | undefined;
  rtoReportTrackingNumberFilter: string;
  rtoReportFbvEnabledFilter: boolean;
  rtoReportFbvWarehouseFilter: string[];
  reconReportStatusFilter: string[];
  reconReportPaymentStartDateFilter: Date;
  reconReportPaymentEndDateFilter: Date;
  reconReportPaymentIdFilter: string;
  reconReportBankReferenceIdFilter: string;
  reconReportInvoiceNumberFilter: string;
  reconReportPaymentCycleStartDateFilter: string[];
  gstrReportPaymentCycleStartDateFilter: string[];
  gstrReportOrderAtStartDateFilter: Date | undefined;
  gstrReportOrderAtEndDateFilter: Date | undefined;
  gstrReportOrderNumberFilter: string;
  gstrReportInvoiceNumberFilter: string;
  gstrReportSKUFilter: string;
  fbvStorageChargesReportStartDateFilter: Date;
  fbvStorageChargesReportEndDateFilter: Date;
  fbvStorageChargesReportProductIdFilter: string;
  fbvStorageChargesReportProductTitleFilter: string;
  issueTrackingIssueRaisedStartDateFilter: Date | undefined;
  issueTrackingIssueRaisedEndDateFilter: Date | undefined;
  issueTrackingIssueResolvedStartDateFilter: Date | undefined;
  issueTrackingIssueResolvedEndDateFilter: Date | undefined;
  issueTrackingTicketIdFilter: string;
  issueTrackingTitleFilter: string;
  issueTrackingStatusFilter: string[];
  issueTrackingDelayedFilter: boolean;
  productCatalogueStatusFilter: ProductCatalogueStatusType;
  productCatalogueCreatedAtStartDateFilter: Date | undefined;
  productCatalogueCreatedAtEndDateFilter: Date | undefined;
  productCatalogueCategoryFilter: string;
  productCatalogueTitleFilter: string;
  productCatalogueSkuFilter: string;
  productCatalogueVinFilter: string;
  productCatalogueHsnCodeFilter: string;
  productCatalogueFbvEnabledFilter: boolean;
  productCatalogueFbvWarehouseFilter: string[];
}

export const initialState: FiltersState = {
  startDateFilter: new Date(new Date().setDate(new Date().getDate() - 15)),
  endDateFilter: new Date(),
  fbvWarehouseFilter: [],
  orderReportOrderCreatedAtStartDateFilter: new Date(new Date().setDate(new Date().getDate() - 15)),
  orderReportOrderCreatedAtEndDateFilter: new Date(),
  orderReportStatusFilter: '',
  orderReportPaymentTypeFilter: '',
  orderReportProductNameFilter: '',
  orderReportProductIdFilter: '',
  orderReportOrderNoFilter: '',
  orderReportAwbNoFilter: '',
  orderReportDelayedShipmentFilter: false,
  orderReportDelayedDeliveryFilter: false,
  orderReportFbvEnabledFilter: false,
  orderReportFbvWarehouseFilter: [],
  sohReportProductNameFilter: '',
  sohReportProductIdFilter: '',
  sohReportInclude0RosFilter: false,
  sohReportFbvEnabledFilter: false,
  sohReportFbvWarehouseFilter: [],
  salesReportOrderCreatedStartDateFilter: new Date(new Date().setDate(new Date().getDate() - 15)),
  salesReportOrderCreatedEndDateFilter: new Date(),
  salesReportCategoryFilter: '',
  salesReportProductIdFilter: '',
  salesReportProductTitleFilter: '',
  salesReportFbvEnabledFilter: false,
  salesReportFbvWarehouseFilter: [],
  lorReportSelectDaysFilter: '',
  lorReportProductNameFilter: '',
  lorReportProductIdFilter: '',
  lorReportFbvEnabledFilter: false,
  lorReportFbvWarehouseFilter: [],
  lorReportShowHistoricalDataFilter: false,
  returnsReportReturnedAtStartDateFilter: new Date(new Date().setDate(new Date().getDate() - 15)),
  returnsReportReturnedAtEndDateFilter: new Date(),
  returnsReportReceivedAtStartDateFilter: undefined,
  returnsReportReceivedAtEndDateFilter: undefined,
  returnsReportManufactureErrorFilter: '',
  returnsReportOrderNumberFilter: '',
  returnsReportSKUFilter: '',
  returnsReportReasonsFilter: [],
  returnsReportTrackingNumberFilter: '',
  returnsReportFbvEnabledFilter: false,
  returnsReportFbvWarehouseFilter: [],
  exchangesReportExchangedAtStartDateFilter: new Date(new Date().setDate(new Date().getDate() - 15)),
  exchangesReportExchangedAtEndDateFilter: new Date(),
  exchangesReportReceivedAtStartDateFilter: undefined,
  exchangesReportReceivedAtEndDateFilter: undefined,
  exchangesReportManufactureErrorFilter: '',
  exchangesReportOrderNumberFilter: '',
  exchangesReportSKUFilter: '',
  exchangesReportReasonsFilter: [],
  exchangesReportTrackingNumberFilter: '',
  exchangesReportFbvEnabledFilter: false,
  exchangesReportFbvWarehouseFilter: [],
  rtoReportOrderedAtStartDateFilter: new Date(new Date().setDate(new Date().getDate() - 15)),
  rtoReportOrderedAtEndDateFilter: new Date(),
  rtoReportDeliveredAtStartDateFilter: undefined,
  rtoReportDeliveredAtEndDateFilter: undefined,
  rtoReportTrackingNumberFilter: "",
  rtoReportFbvEnabledFilter: false,
  rtoReportFbvWarehouseFilter: [],
  reconReportStatusFilter: [],
  reconReportPaymentStartDateFilter: new Date(new Date().setDate(new Date().getDate() - 30)),
  reconReportPaymentEndDateFilter: new Date(new Date().setDate(new Date().getDate() + 30)),
  reconReportPaymentIdFilter: '',
  reconReportBankReferenceIdFilter: '',
  reconReportInvoiceNumberFilter: '',
  reconReportPaymentCycleStartDateFilter: [],
  gstrReportPaymentCycleStartDateFilter: [],
  gstrReportOrderAtStartDateFilter: undefined,
  gstrReportOrderAtEndDateFilter: undefined,
  gstrReportOrderNumberFilter: '',
  gstrReportInvoiceNumberFilter: '',
  gstrReportSKUFilter: '',
  fbvStorageChargesReportStartDateFilter: new Date(today.getFullYear(), today.getMonth(), 1),
  fbvStorageChargesReportEndDateFilter: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
  fbvStorageChargesReportProductIdFilter: '',
  fbvStorageChargesReportProductTitleFilter: '',
  issueTrackingIssueRaisedStartDateFilter: new Date(2024, 3, 1),
  issueTrackingIssueRaisedEndDateFilter: new Date(),
  issueTrackingIssueResolvedStartDateFilter: undefined,
  issueTrackingIssueResolvedEndDateFilter: undefined,
  issueTrackingTicketIdFilter: '',
  issueTrackingTitleFilter: '',
  issueTrackingStatusFilter: [],
  issueTrackingDelayedFilter: false,
  productCatalogueStatusFilter: 'all',
  productCatalogueCreatedAtStartDateFilter: undefined,
  productCatalogueCreatedAtEndDateFilter: undefined,
  productCatalogueCategoryFilter: '',
  productCatalogueTitleFilter: '',
  productCatalogueSkuFilter: '',
  productCatalogueVinFilter: '',
  productCatalogueHsnCodeFilter: '',
  productCatalogueFbvEnabledFilter: false,
  productCatalogueFbvWarehouseFilter: [],
};

export const warehouseListApi = createAsyncThunk(
  "warehouseListApi",
  async ({headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get("/forge/dashboards/warehouse_list", config);
      const resData = response.data;
      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setStartDateFilter: (state, action: PayloadAction<Date>) => {
      state.startDateFilter = action.payload;
    },
    setEndDateFilter: (state, action: PayloadAction<Date>) => {
      state.endDateFilter = action.payload;
    },
    setOrderReportOrderCreatedAtStartDateFilter: (state, action: PayloadAction<Date>) => {
      state.orderReportOrderCreatedAtStartDateFilter = action.payload;
    },
    setOrderReportOrderCreatedAtEndDateFilter: (state, action: PayloadAction<Date>) => {
      state.orderReportOrderCreatedAtEndDateFilter = action.payload;
    },
    setOrderReportStatusFilter: (state, action: PayloadAction<string>) => {
      state.orderReportStatusFilter = action.payload;
    },
    setOrderReportPaymentTypeFilter: (state, action: PayloadAction<string>) => {
      state.orderReportPaymentTypeFilter = action.payload;
    },
    setOrderReportProductNameFilter: (state, action: PayloadAction<string>) => {
      state.orderReportProductNameFilter = action.payload;
    },
    setOrderReportProductIdFilter: (state, action: PayloadAction<string>) => {
      state.orderReportProductIdFilter = action.payload;
    },
    setOrderReportOrderNoFilter: (state, action: PayloadAction<string>) => {
      state.orderReportOrderNoFilter = action.payload;
    },
    setOrderReportAwbNoFilter: (state, action: PayloadAction<string>) => {
      state.orderReportAwbNoFilter = action.payload;
    },
    setOrderReportDelayedShipmentFilter: (state, action: PayloadAction<boolean>) => {
      state.orderReportDelayedShipmentFilter = action.payload;
    },
    setOrderReportDelayedDeliveryFilter: (state, action: PayloadAction<boolean>) => {
      state.orderReportDelayedDeliveryFilter = action.payload;
    },
    setOrderReportFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.orderReportFbvEnabledFilter = action.payload;
    },
    setOrderReportFbvWarehouseFilter: (state, action: PayloadAction<string[]>) => {
      state.orderReportFbvWarehouseFilter = action.payload;
    },
    setSalesReportOrderCreatedStartDateFilter: (state, action: PayloadAction<Date>) => {
      state.salesReportOrderCreatedStartDateFilter = action.payload;
    },
    setSalesReportOrderCreatedEndDateFilter: (state, action: PayloadAction<Date>) => {
      state.salesReportOrderCreatedEndDateFilter = action.payload;
    },
    setSalesReportCategoryFilter: (state, action: PayloadAction<string>) => {
      state.salesReportCategoryFilter = action.payload;
    },
    setSalesReportProductIdFilter: (state, action: PayloadAction<string>) => {
      state.salesReportProductIdFilter = action.payload;
    },
    setSalesReportProductTitleFilter: (state, action: PayloadAction<string>) => {
      state.salesReportProductTitleFilter = action.payload;
    },
    setSalesReportFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.salesReportFbvEnabledFilter = action.payload;
    },
    setSalesReportFbvWarehouseFilter: (state, action: PayloadAction<string[]>) => {
      state.salesReportFbvWarehouseFilter = action.payload;
    },
    setSohReportProductNameFilter: (state, action: PayloadAction<string>) => {
      state.sohReportProductNameFilter = action.payload;
    },
    setSohReportProductIdFilter: (state, action: PayloadAction<string>) => {
      state.sohReportProductIdFilter = action.payload;
    },
    setSohReportInclude0RosFilter: (state, action: PayloadAction<boolean>) => {
      state.sohReportInclude0RosFilter = action.payload;
    },
    setSohReportFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.sohReportFbvEnabledFilter = action.payload;
    },
    setSohReportFbvWarehouseFilter: (state, action: PayloadAction<string[]>) => {
      state.sohReportFbvWarehouseFilter = action.payload;
    },
    setLorReportSelectDaysFilter: (state, action: PayloadAction<string>) => {
      state.lorReportSelectDaysFilter = action.payload;
    },
    setLorReportProductNameFilter: (state, action: PayloadAction<string>) => {
      state.lorReportProductNameFilter = action.payload;
    },
    setLorReportProductIdFilter: (state, action: PayloadAction<string>) => {
      state.lorReportProductIdFilter = action.payload;
    },
    setLorReportFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.lorReportFbvEnabledFilter = action.payload;
    },
    setLorReportFbvWarehouseFilter: (state, action: PayloadAction<string[]>) => {
      state.lorReportFbvWarehouseFilter = action.payload;
    },
    setLorReportShowHistoricalDataFilter: (state, action: PayloadAction<boolean>) => {
      state.lorReportShowHistoricalDataFilter = action.payload;
    },
    setReturnsReportReturnedAtStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.returnsReportReturnedAtStartDateFilter = action.payload;
    },
    setReturnsReportReturnedAtEndDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.returnsReportReturnedAtEndDateFilter = action.payload;
    },
    setReturnsReportReceivedAtStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.returnsReportReceivedAtStartDateFilter = action.payload;
    },
    setReturnsReportReceivedAtEndDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.returnsReportReceivedAtEndDateFilter = action.payload;
    },
    setReturnsReportManufactureErrorFilter: (state, action: PayloadAction<string>) => {
      state.returnsReportManufactureErrorFilter = action.payload;
    },
    setReturnsReportOrderNumberFilter: (state, action: PayloadAction<string>) => {
      state.returnsReportOrderNumberFilter = action.payload;
    },
    setReturnsReportSKUFilter: (state, action: PayloadAction<string>) => {
      state.returnsReportSKUFilter = action.payload;
    },
    setReturnsReportReasonsFilter: (state, action: PayloadAction<string[]>) => {
      state.returnsReportReasonsFilter = action.payload;
    },
    setReturnsReportTrackingNumberFilter: (state, action: PayloadAction<string>) => {
      state.returnsReportTrackingNumberFilter = action.payload;
    },
    setReturnsReportFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.returnsReportFbvEnabledFilter = action.payload;
    },
    setReturnsReportFbvWarehouseFilter: (state, action: PayloadAction<string[]>) => {
      state.returnsReportFbvWarehouseFilter = action.payload;
    },
    setExchangesReportExchangedAtStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.exchangesReportExchangedAtStartDateFilter = action.payload;
    },
    setExchangesReportExchangedAtEndDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.exchangesReportExchangedAtEndDateFilter = action.payload;
    },
    setExchangesReportReceivedAtStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.exchangesReportReceivedAtStartDateFilter = action.payload;
    },
    setExchangesReportReceivedAtEndDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.exchangesReportReceivedAtEndDateFilter = action.payload;
    },
    setExchangesReportManufactureErrorFilter: (state, action: PayloadAction<string>) => {
      state.exchangesReportManufactureErrorFilter = action.payload;
    },
    setExchangesReportOrderNumberFilter: (state, action: PayloadAction<string>) => {
      state.exchangesReportOrderNumberFilter = action.payload;
    },
    setExchangesReportSKUFilter: (state, action: PayloadAction<string>) => {
      state.exchangesReportSKUFilter = action.payload;
    },
    setExchangesReportReasonsFilter: (state, action: PayloadAction<string[]>) => {
      state.exchangesReportReasonsFilter = action.payload;
    },
    setExchangesReportTrackingNumberFilter: (state, action: PayloadAction<string>) => {
      state.exchangesReportTrackingNumberFilter = action.payload;
    },
    setExchangesReportFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.exchangesReportFbvEnabledFilter = action.payload;
    },
    setExchangesReportFbvWarehouseFilter: (state, action: PayloadAction<string[]>) => {
      state.exchangesReportFbvWarehouseFilter = action.payload;
    },
    setRtoReportOrderedAtStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.rtoReportOrderedAtStartDateFilter = action.payload;
    },
    setRtoReportOrderedAtEndDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.rtoReportOrderedAtEndDateFilter = action.payload;
    },
    setRtoReportDeliveredAtStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.rtoReportDeliveredAtStartDateFilter = action.payload;
    },
    setRtoReportDeliveredAtEndDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.rtoReportDeliveredAtEndDateFilter = action.payload;
    },
    setRtoReportTrackingNumberFilter: (state, action: PayloadAction<string>) => {
      state.rtoReportTrackingNumberFilter = action.payload;
    },
    setRtoReportFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.rtoReportFbvEnabledFilter = action.payload;
    },
    setRtoReportFbvWarehouseFilter: (state, action: PayloadAction<string[]>) => {
      state.rtoReportFbvWarehouseFilter = action.payload;
    },
    setReconReportStatusFilter: (state, action: PayloadAction<string[]>) => {
      state.reconReportStatusFilter = action.payload;
    },
    setReconReportPaymentStartDateFilter: (state, action: PayloadAction<Date>) => {
      state.reconReportPaymentStartDateFilter = action.payload;
    },
    setReconReportPaymentEndDateFilter: (state, action: PayloadAction<Date>) => {
      state.reconReportPaymentEndDateFilter = action.payload;
    },
    setReconReportPaymentIdFilter: (state, action: PayloadAction<string>) => {
      state.reconReportPaymentIdFilter = action.payload;
    },
    setReconReportBankReferenceIdFilter: (state, action: PayloadAction<string>) => {
      state.reconReportBankReferenceIdFilter = action.payload;
    },
    setReconReportInvoiceNumberFilter: (state, action: PayloadAction<string>) => {
      state.reconReportInvoiceNumberFilter = action.payload;
    },
    setReconReportPaymentCycleStartDateFilter: (state, action: PayloadAction<string[]>) => {
      state.reconReportPaymentCycleStartDateFilter = action.payload;
    },
    setGstrReportPaymentCycleStartDateFilter: (state, action: PayloadAction<string[]>) => {
      state.gstrReportPaymentCycleStartDateFilter = action.payload;
    },
    setGstrReportOrderAtStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.gstrReportOrderAtStartDateFilter = action.payload;
    },
    setGstrReportOrderAtEndDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.gstrReportOrderAtEndDateFilter = action.payload;
    },
    setGstrReportOrderNumberFilter: (state, action: PayloadAction<string>) => {
      state.gstrReportOrderNumberFilter = action.payload;
    },
    setGstrReportInvoiceNumberFilter: (state, action: PayloadAction<string>) => {
      state.gstrReportInvoiceNumberFilter = action.payload;
    },
    setGstrReportSKUFilter: (state, action: PayloadAction<string>) => {
      state.gstrReportSKUFilter = action.payload;
    },
    setFbvStorageChargesReportStartDateFilter: (state, action: PayloadAction<Date>) => {
      state.fbvStorageChargesReportStartDateFilter = action.payload;
    },
    setFbvStorageChargesReportEndDateFilter: (state, action: PayloadAction<Date>) => {
      state.fbvStorageChargesReportEndDateFilter = action.payload;
    },
    setFbvStorageChargesReportProductIdFilter: (state, action: PayloadAction<string>) => {
      state.fbvStorageChargesReportProductIdFilter = action.payload;
    },
    setFbvStorageChargesReportProductTitleFilter: (state, action: PayloadAction<string>) => {
      state.fbvStorageChargesReportProductTitleFilter = action.payload;
    },
    setIssueTrackingIssueRaisedStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.issueTrackingIssueRaisedStartDateFilter = action.payload;
    },
    setIssueTrackingIssueRaisedEndDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.issueTrackingIssueRaisedEndDateFilter = action.payload;
    },
    setIssueTrackingIssueResolvedStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.issueTrackingIssueResolvedStartDateFilter = action.payload;
    },
    setIssueTrackingIssueResolvedEndDateFilter: (state, action: PayloadAction<Date |undefined>) => {
      state.issueTrackingIssueResolvedEndDateFilter = action.payload;
    },
    setIssueTrackingTicketIdFilter: (state, action: PayloadAction<string>) => {
      state.issueTrackingTicketIdFilter = action.payload;
    },
    setIssueTrackingTitleFilter: (state, action: PayloadAction<string>) => {
      state.issueTrackingTitleFilter = action.payload;
    },
    setIssueTrackingStatusFilter: (state, action: PayloadAction<string[]>) => {
      state.issueTrackingStatusFilter = action.payload;
    },
    setIssueTrackingDelayedFilter: (state, action: PayloadAction<boolean>) => {
      state.issueTrackingDelayedFilter = action.payload;
    },
    setProductCatalogueStatusFilter: (state, action: PayloadAction<ProductCatalogueStatusType>) => {
      state.productCatalogueStatusFilter = action.payload;
    },
    setProductCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.productCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setProductCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.productCatalogueCreatedAtEndDateFilter = action.payload;
    },
    setProductCatalogueCategoryFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueCategoryFilter = action.payload;
    },
    setProductCatalogueTitleFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueTitleFilter = action.payload;
    },
    setProductCatalogueSkuFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueSkuFilter = action.payload;
    },
    setProductCatalogueVinFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueVinFilter = action.payload;
    },
    setProductCatalogueHsnCodeFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueHsnCodeFilter = action.payload;
    },
    setProductCatalogueFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.productCatalogueFbvEnabledFilter = action.payload;
    },
    setProductCatalogueFbvWarehouseFilter: (state, action: PayloadAction<string[]>) => {
      state.productCatalogueFbvWarehouseFilter = action.payload;
    },
    resetFilter: (state) => {
      Object.assign(state, { ...initialState, fbvWarehouseFilter: state.fbvWarehouseFilter });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        warehouseListApi.fulfilled,
        (state, action: PayloadAction<FbvWarehouseHash[]>) => {
          state.fbvWarehouseFilter = action.payload;
        }
      )
  }
});

export const {
  setStartDateFilter,
  setEndDateFilter,
  setOrderReportOrderCreatedAtStartDateFilter,
  setOrderReportOrderCreatedAtEndDateFilter,
  setOrderReportStatusFilter,
  setOrderReportPaymentTypeFilter,
  setOrderReportProductNameFilter,
  setOrderReportProductIdFilter,
  setOrderReportOrderNoFilter,
  setOrderReportAwbNoFilter,
  setOrderReportDelayedShipmentFilter,
  setOrderReportDelayedDeliveryFilter,
  setOrderReportFbvEnabledFilter,
  setOrderReportFbvWarehouseFilter,
  setSalesReportOrderCreatedStartDateFilter,
  setSalesReportOrderCreatedEndDateFilter,
  setSalesReportCategoryFilter,
  setSalesReportProductIdFilter,
  setSalesReportProductTitleFilter,
  setSalesReportFbvEnabledFilter,
  setSalesReportFbvWarehouseFilter,
  setSohReportProductNameFilter,
  setSohReportProductIdFilter,
  setSohReportInclude0RosFilter,
  setSohReportFbvEnabledFilter,
  setSohReportFbvWarehouseFilter,
  setLorReportSelectDaysFilter,
  setLorReportProductNameFilter,
  setLorReportProductIdFilter,
  setLorReportFbvEnabledFilter,
  setLorReportFbvWarehouseFilter,
  setLorReportShowHistoricalDataFilter,
  setReturnsReportReturnedAtStartDateFilter,
  setReturnsReportReturnedAtEndDateFilter,
  setReturnsReportReceivedAtStartDateFilter,
  setReturnsReportReceivedAtEndDateFilter,
  setReturnsReportManufactureErrorFilter,
  setReturnsReportOrderNumberFilter,
  setReturnsReportSKUFilter,
  setReturnsReportReasonsFilter,
  setReturnsReportTrackingNumberFilter,
  setReturnsReportFbvEnabledFilter,
  setReturnsReportFbvWarehouseFilter,
  setExchangesReportExchangedAtStartDateFilter,
  setExchangesReportExchangedAtEndDateFilter,
  setExchangesReportReceivedAtStartDateFilter,
  setExchangesReportReceivedAtEndDateFilter,
  setExchangesReportManufactureErrorFilter,
  setExchangesReportOrderNumberFilter,
  setExchangesReportSKUFilter,
  setExchangesReportReasonsFilter,
  setExchangesReportTrackingNumberFilter,
  setExchangesReportFbvEnabledFilter,
  setExchangesReportFbvWarehouseFilter,
  setRtoReportOrderedAtStartDateFilter,
  setRtoReportOrderedAtEndDateFilter,
  setRtoReportDeliveredAtStartDateFilter,
  setRtoReportDeliveredAtEndDateFilter,
  setRtoReportTrackingNumberFilter,
  setRtoReportFbvEnabledFilter,
  setRtoReportFbvWarehouseFilter,
  setReconReportStatusFilter,
  setReconReportPaymentStartDateFilter,
  setReconReportPaymentEndDateFilter,
  setReconReportPaymentIdFilter,
  setReconReportBankReferenceIdFilter,
  setReconReportInvoiceNumberFilter,
  setReconReportPaymentCycleStartDateFilter,
  setGstrReportPaymentCycleStartDateFilter,
  setGstrReportOrderAtStartDateFilter,
  setGstrReportOrderAtEndDateFilter,
  setGstrReportOrderNumberFilter,
  setGstrReportInvoiceNumberFilter,
  setGstrReportSKUFilter,
  setFbvStorageChargesReportStartDateFilter,
  setFbvStorageChargesReportEndDateFilter,
  setFbvStorageChargesReportProductIdFilter,
  setFbvStorageChargesReportProductTitleFilter,
  setIssueTrackingIssueRaisedStartDateFilter,
  setIssueTrackingIssueRaisedEndDateFilter,
  setIssueTrackingIssueResolvedStartDateFilter,
  setIssueTrackingIssueResolvedEndDateFilter,
  setIssueTrackingTicketIdFilter,
  setIssueTrackingTitleFilter,
  setIssueTrackingStatusFilter,
  setIssueTrackingDelayedFilter,
  setProductCatalogueStatusFilter,
  setProductCatalogueCreatedAtStartDateFilter,
  setProductCatalogueCreatedAtEndDateFilter,
  setProductCatalogueCategoryFilter,
  setProductCatalogueTitleFilter,
  setProductCatalogueSkuFilter,
  setProductCatalogueVinFilter,
  setProductCatalogueHsnCodeFilter,
  setProductCatalogueFbvEnabledFilter,
  setProductCatalogueFbvWarehouseFilter,
  resetFilter
} = filtersSlice.actions;
export default filtersSlice.reducer;
