import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {Box,Button,Fab, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import styles from './GSTRReport.module.scss';
import Loader from "components/atoms/Loader/Loader";
import { 
  setGstrReportPaymentCycleStartDateFilter,
  setGstrReportOrderAtStartDateFilter,
  setGstrReportOrderAtEndDateFilter,
  setGstrReportOrderNumberFilter,
  setGstrReportInvoiceNumberFilter,
  setGstrReportSKUFilter,
  resetFilter,
} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import {checkNA, downloadFile } from "../../helpers/utils";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { gstrReportColumns } from "./GSTRReport.constant";
import { GSTRDataHash, gstrReportApi, gstrReportDownloadReportApi, setSort} from "../../slices/gstrSlice";
import { DateRangeType } from "../../constants";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import MultiSelectFilter from "components/molecules/MultiSelectFilter/MultiSelectFilter";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ColorButton from "components/atoms/ColorButton/ColorButton";
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";

const PAGE_TITLE = 'GSTR Report'

const GSTRReport = () => {

  const dispatch = useAppDispatch();

  const gstrReportData = useAppSelector((state) => state.gstrReport);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [filterOpen, setFilterOpen] = useState(false);

  const avaialbleCycles = gstrReportData.gstrReportData?.available_cycles || [];

  const gstrReportPaymentCycleStartDateFilter = useAppSelector((state: RootState) => state.filters.gstrReportPaymentCycleStartDateFilter);
  const gstrReportOrderAtStartDateFilter = useAppSelector((state: RootState) => state.filters.gstrReportOrderAtStartDateFilter);
  const gstrReportOrderAtEndDateFilter = useAppSelector((state: RootState) => state.filters.gstrReportOrderAtEndDateFilter);
  const gstrReportOrderNumberFilter = useAppSelector((state: RootState) => state.filters.gstrReportOrderNumberFilter);
  const gstrReportInvoiceNumberFilter = useAppSelector((state: RootState) => state.filters.gstrReportInvoiceNumberFilter);
  const gstrReportSKUFilter = useAppSelector((state: RootState) => state.filters.gstrReportSKUFilter);

  const userName = useAppSelector((state) => checkNA(state.auth.basicUserInfo?.name));
  const gstNumber = useAppSelector((state) => checkNA(state.auth.basicUserInfo?.gst_number));

  const handleSort = (column: keyof GSTRDataHash) => {
    const direction = column === gstrReportData.sortedColumn && gstrReportData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href,
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const trackFilterEvent = async () => {
    if(appliedSearchFilters.length === 0) return;
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: [...appliedSearchFilters.map(f => f.label)]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const clearFilters = () => {
    dispatch(resetFilter());
    setFilterApplied(true);
  }

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(gstrReportDownloadReportApi({
          headers: headers,
          gstrReportPaymentCycleStartDateFilter,
          gstrReportOrderAtStartDateFilter,
          gstrReportOrderAtEndDateFilter,
          gstrReportOrderNumberFilter,
          gstrReportInvoiceNumberFilter,
          gstrReportSKUFilter,
        }));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              report_name: PAGE_TITLE,
              download_type: 'Report'
            }
          );
        }
      }
      if(!fab) button.innerHTML = 'Download';
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(gstrReportApi({
        headers: headers,
        gstrReportPaymentCycleStartDateFilter,
        gstrReportOrderAtStartDateFilter,
        gstrReportOrderAtEndDateFilter,
        gstrReportOrderNumberFilter,
        gstrReportInvoiceNumberFilter,
        gstrReportSKUFilter,
      })).unwrap();
      setPage(0);
      setFilterApplied(false);
      setLoaderActive(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);


  const searchByOptions = [
    {id: 'sku', label: 'SKU Code'},
    {id: 'invoiceNumber', label: 'Invoice Number'},
    {id: 'orderNumber', label: 'Order Number'},
  ]

  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'sku': {
        dispatch(setGstrReportSKUFilter(value as string)); break;
      }
      case 'invoiceNumber': {
        dispatch(setGstrReportInvoiceNumberFilter(value as string)); break;
      }
      case 'orderNumber': {
        dispatch(setGstrReportOrderNumberFilter(value as string)); break;
      }
      case 'paymentCycle': {
        dispatch(setGstrReportPaymentCycleStartDateFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'paymentCycle' : {
          dispatch(setGstrReportPaymentCycleStartDateFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'paymentCycle')
      handleFilterValueChange({id, value: []});
    else
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setGstrReportSKUFilter(''));
    dispatch(setGstrReportInvoiceNumberFilter(''));
    dispatch(setGstrReportOrderNumberFilter(''));
    dispatch(setGstrReportPaymentCycleStartDateFilter([]));
    setFilterApplied(true);
  }

  const availableCyclesOptions = avaialbleCycles.map((ac => ({
    label: `${ac.start_date} - ${ac.end_date}`, value: ac.start_date,
  })));

  const appliedSearchFilters:AppliedFilterType[] = [
    ...(!!gstrReportSKUFilter ? [{id: 'sku', label: 'SKU Code', value: gstrReportSKUFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!gstrReportInvoiceNumberFilter ? [{id: 'invoiceNumber', label: 'Invoice Number', value: gstrReportInvoiceNumberFilter, type: 'search'}] : [])  as AppliedFilterType[],
    ...(!!gstrReportOrderNumberFilter ? [{id: 'orderNumber', label: 'Order Number', value: gstrReportOrderNumberFilter, type: 'search'}] : [])  as AppliedFilterType[],
    ...(!!gstrReportPaymentCycleStartDateFilter.length ? [{id: 'paymentCycle', label: 'Payment Cycle', value: gstrReportPaymentCycleStartDateFilter, options: availableCyclesOptions, type: 'multi-select'}] : []) as AppliedFilterType[],
  ];

  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setGstrReportOrderAtStartDateFilter(ranges.startDate as Date));
    dispatch(setGstrReportOrderAtEndDateFilter(ranges.endDate as Date));
    setFilterApplied(true);
  };

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="GSTR Report">
        <div className={styles["user-gst-info"]}>
          <p className={styles["gst-user"]}>{userName}</p>
          <p className={styles["gst-number"]}>GSTIN: {gstNumber}</p>
        </div>
      </MainHeader>
    

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              <MultiSelectFilter 
                label={'Payment Cycle'}
                values={gstrReportPaymentCycleStartDateFilter}
                options={availableCyclesOptions}
                onSubmit={(values) => {
                  dispatch(setGstrReportPaymentCycleStartDateFilter(values));
                  setFilterApplied(true);
                }}
              />
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          multiSelectFilters={[
            {
              id: 'paymentCycle',
              label: 'Payment Cycle',
              type: 'multiSelect',
              value: gstrReportPaymentCycleStartDateFilter,
              options: availableCyclesOptions,
            },
          ]}
      />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
         <TableManager<GSTRDataHash>
         data={gstrReportData?.gstrReportData?.payment_data?.gstr_data || []}
         columns={gstrReportColumns()}
         sortedColumn={gstrReportData.sortedColumn}
         handleSort={handleSort}
         sortDirection={gstrReportData.sortDirection}
         showPagination
         currentPage={page}
         rowPerPage={rowsPerPage}
         onPageChange={handleChangePage}
         onRowPerPageChange={handleChangeRowsPerPage}
         rowsPerPageOptions={[10, 25, 100]}
       />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={gstrReportOrderAtStartDateFilter}
        endDate={gstrReportOrderAtStartDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
      />
    </Box>
  );
};
  
export default GSTRReport;
