import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {Box, Button, Fab, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import { setSort, fbvStockChargesReportDownloadReportApi, fbvStockChargesReportApi } from "../../slices/fbvStockChargesReportSlice";
import styles from "./FbvStockChargesReport.module.scss";
import {
  setFbvStorageChargesReportStartDateFilter,
  setFbvStorageChargesReportEndDateFilter,
  resetFilter,
  setFbvStorageChargesReportProductIdFilter,
  setFbvStorageChargesReportProductTitleFilter
} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { fixedColumn, fbvStockChargesReportColumns } from "./FbvStockChargesReport.constant";
import { downloadFile } from "../../helpers/utils";
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import SelectFilter from "components/molecules/SelectFilter/SelectFilter";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import Loader from "components/atoms/Loader/Loader";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { FbvStorageChargesDataHash } from "slices/fbvStockChargesReportSlice";
import { DataCol, DataRow } from "components/atoms/ChartData/ChartData";
import { formatCurrency, lakhFormat } from "helpers/formatUtils";

const searchByOptions = [
  {id: 'productId', label: 'Product ID'},
  {id: 'productName', label: 'Product Name'},
]

const PAGE_TITLE = 'FBV Storage Fees Report'

const FbvStockChargesReport = () => {

  const dispatch = useAppDispatch();
  const storage_fee = useAppSelector((state) => state.fbvStockChargesReport?.fbvStockChargesReportData?.storage_fee) || 'NA';
  const pickpack_fee = useAppSelector((state) => state.fbvStockChargesReport?.fbvStockChargesReportData?.pickpack_fee) || 'NA';

  const fbvStockChargesReportReport = useAppSelector((state) => state.fbvStockChargesReport);
  const fbvStorageChargesReportStartDateFilter = useAppSelector((state: RootState) => state.filters.fbvStorageChargesReportStartDateFilter);
  const fbvStorageChargesReportEndDateFilter = useAppSelector((state: RootState) => state.filters.fbvStorageChargesReportEndDateFilter);
  const fbvStorageChargesReportProductIdFilter = useAppSelector((state: RootState) => state.filters.fbvStorageChargesReportProductIdFilter);
  const fbvStorageChargesReportProductTitleFilter = useAppSelector((state: RootState) => state.filters.fbvStorageChargesReportProductTitleFilter);
  const monthValue = `${fbvStorageChargesReportStartDateFilter.getMonth()}`;

  const [filterOpen, setFilterOpen] = useState(false);

  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(false);
  const [monthOptions, setMonthOptions] = useState<{label: string, value: string}[]>([]);

  const handleSort = (column: keyof FbvStorageChargesDataHash) => {
    const direction = column === fbvStockChargesReportReport.sortedColumn && fbvStockChargesReportReport.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  // TODO: Need to update
  useEffect(() => {
    const today = new Date();
    const options = [];
    for(let i = 3 ; i <= today.getMonth() ; i += 1){
      options.push({
        label: (new Date(today.getFullYear() , i, 1)).toLocaleString('default', { month: 'long' }),
        value: `${i}`,
      });
    }
    setMonthOptions(options);
  }, []);

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT,
      {
        page_link: window.location.href,
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const appliedSearchFilters: AppliedFilterType[] = [
    ...(!!fbvStorageChargesReportProductIdFilter ? [{id: 'productId', label: 'Product ID', value: fbvStorageChargesReportProductIdFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!fbvStorageChargesReportProductTitleFilter ? [{id: 'productName', label: 'Product Name', value: fbvStorageChargesReportProductTitleFilter, type: 'search'}] : []) as AppliedFilterType[] ,
  ];

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(fbvStockChargesReportApi({
        fbvStorageChargesReportStartDateFilter,
        fbvStorageChargesReportEndDateFilter,
        fbvStorageChargesReportProductIdFilter,
        fbvStorageChargesReportProductTitleFilter,
        headers: headers
      })).unwrap();
      setPage(0);
      setLoaderActive(false);
      setFilterApplied(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleStartEndDateSelect = (month: number) => {
    const today = new Date();
    const startDate =  new Date(today.getFullYear(), month, 1);
    const endDate = new Date(today.getFullYear(), month + 1, 0);
    dispatch(setFbvStorageChargesReportStartDateFilter(startDate));
    dispatch(setFbvStorageChargesReportEndDateFilter(endDate));
  };

  const trackFilterEvent = async () => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: ['Month' , ...appliedSearchFilters.map(f => f.label)]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied,]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(fbvStockChargesReportDownloadReportApi({
          fbvStorageChargesReportStartDateFilter,
          fbvStorageChargesReportEndDateFilter,
          fbvStorageChargesReportProductIdFilter,
          fbvStorageChargesReportProductTitleFilter,
          headers: headers
        }));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              download_type: 'Report',
              report_name: PAGE_TITLE,
            }
          );
        }
      }else{
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if(!fab) button.innerHTML = 'Download';
    }
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'productId': {
        dispatch(setFbvStorageChargesReportProductIdFilter(value as string)); break;
      }
      case 'productName': {
        dispatch(setFbvStorageChargesReportProductTitleFilter(value as string)); break;
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setFbvStorageChargesReportProductIdFilter(''));
    dispatch(setFbvStorageChargesReportProductTitleFilter(''));
    setFilterApplied(true);
  }

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'monthOfCharges' : {
          handleStartEndDateSelect(+(value as string));
        }
      }
    }
    setFilterApplied(true);
  }

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="FBV Storage Charges">
        {!isMobile && (
          <ChargesHeaderSection 
            data={[
              {label: 'FBV storage fees:', value: storage_fee === 'NA' ? 'NA' : `${formatCurrency(storage_fee)}/m`},
              {label: 'Pick & Pack Fees:', value: pickpack_fee === 'NA' ? 'NA' : `${formatCurrency(pickpack_fee)}/qty`}, 
            ]}
          />
        )}
      </MainHeader>

      {isMobile ? (
        <Box className={styles.chargesWrapper}>
          <DataRow
            className={styles['charges-row']}
            separator
            children={[
              <DataCol 
                colClass={styles['charge-col']}
                labelClass={styles["charge-label"]}
                valueClass={styles['charge-value']}
                label={"FBV storage fees:"}
                value={storage_fee === 'NA' ? 'NA' : `${formatCurrency(storage_fee)}/m`}
              />,
              <DataCol
              colClass={styles['charge-col']}
              labelClass={styles["charge-label"]}
              valueClass={styles['charge-value']}
                label={"Pick & Pack Fees:"} 
                value={pickpack_fee === 'NA' ? 'NA' : `${formatCurrency(pickpack_fee)}/qty`}
              />,
            ]}
          />
        </Box>
      ): null}

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>

          {!isMobile? (
            <>
              <SelectFilter
                label={'Month'}
                value={monthValue}
                options={monthOptions}
                onChange={(value) => {
                  handleStartEndDateSelect(+value);
                  setFilterApplied(true);
                }}
              />
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}

          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
      
      <SwipeableFilters
        open={filterOpen}
        onOpen={() => setFilterOpen(true)}
        onClose={() => setFilterOpen(false)}
        onAction={handleSwipeableDrawerFilters}
        selectFilters={[
          {
            id: 'monthOfCharges',
            label: 'Month',
            type: 'select',
            value: monthValue,
            options: monthOptions,
          },
        ]}
      />

      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<FbvStorageChargesDataHash>
          data={fbvStockChargesReportReport?.fbvStockChargesReportData?.fbv_stock_fee_report || []}
          columns={fbvStockChargesReportColumns(fbvStorageChargesReportStartDateFilter.getMonth())}
          sortedColumn={fbvStockChargesReportReport.sortedColumn}
          handleSort={handleSort}
          sortDirection={fbvStockChargesReportReport.sortDirection}
          showPagination
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={isMobile ? fixedColumn: undefined}
        />
      )}

    </Box>
  );
};

export default FbvStockChargesReport;

type ChargesHeaderSectionPropType = {
  data: {label: string, value: string}[]
}

const ChargesHeaderSection:React.FC<ChargesHeaderSectionPropType> = (props) => {
  const {data} = props;
  return (
    <DataRow
      separator
      children={data.map(item => {
        return (
          <Box className={styles.chargeData}>
            <p className={styles.chargeLabel}>{item.label}</p>
            <p className={styles.chargeVal}>{item.value}</p>
          </Box>
        )
      })}
    />
  )
}