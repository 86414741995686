import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {Box, Button, Fab, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import { setSort, orderTrackingApi, orderTrackingDownloadReportApi, ShippingData } from "../../slices/orderTrackingSlice";
import FbvIcon from '../../images/fbv-icon.png';
import styles from "./OrderTracking.module.scss";
import {
  setOrderReportOrderCreatedAtStartDateFilter,
  setOrderReportOrderCreatedAtEndDateFilter,
  resetFilter,
  setOrderReportStatusFilter,
  setOrderReportPaymentTypeFilter,
  setOrderReportProductIdFilter,
  setOrderReportProductNameFilter,
  setOrderReportOrderNoFilter,
  setOrderReportAwbNoFilter,
  setOrderReportDelayedShipmentFilter,
  setOrderReportDelayedDeliveryFilter,
  setOrderReportFbvEnabledFilter,
  setOrderReportFbvWarehouseFilter,
} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { fixedColumn, orderTrackingColumns } from "./OrderTracking.constant";
import { downloadFile } from "../../helpers/utils";
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import SelectFilter from "components/molecules/SelectFilter/SelectFilter";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import Loader from "components/atoms/Loader/Loader";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";
import { DateRangeType } from "./../../constants";
import ColorButton from "components/atoms/ColorButton/ColorButton";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import MultiStepProgressDrawer from "components/molecules/MultiStepProgressDrawer/MultiStepProgressDrawer";
import MultiSelectFilter from 'components/molecules/MultiSelectFilter/MultiSelectFilter';

const orderStatusOptions = [
  {label: 'All', value: ''},
  {label: 'Open', value: 'Open'},
  {label: 'Dispatched', value: 'Dispatched'},
  {label: 'Delivered', value: 'Delivered'},
  {label: 'RTO', value: 'RTO'},
  {label: 'Cancelled', value: 'Cancelled'},
  {label: 'Returned', value: 'Returned'},
]

const orderPaymentOptions = [
  {label: 'All', value: ''},
  {label: 'COD', value: 'COD'},
  {label: 'Prepaid', value: 'Prepaid'},
]

const searchByOptions = [
  {id: 'productId', label: 'Product ID'},
  {id: 'productName', label: 'Product Name'},
  {id: 'orderNumber', label: 'Order Number'},
  {id: 'awbNumber', label: 'AWB Number'},
]


const PAGE_TITLE = 'Orders Tracking'

const OrderTracking = () => {

  const dispatch = useAppDispatch();
  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const orderTrackingData = useAppSelector((state) => state.orderTracking);
  const orderReportOrderCreatedAtStartDateFilter = useAppSelector((state: RootState) => state.filters.orderReportOrderCreatedAtStartDateFilter);
  const orderReportOrderCreatedAtEndDateFilter = useAppSelector((state: RootState) => state.filters.orderReportOrderCreatedAtEndDateFilter);
  const statusFilter = useAppSelector((state: RootState) => state.filters.orderReportStatusFilter);
  const paymentTypeFilter = useAppSelector((state: RootState) => state.filters.orderReportPaymentTypeFilter);
  const productIdFilter = useAppSelector((state: RootState) => state.filters.orderReportProductIdFilter);
  const productNameFilter = useAppSelector((state: RootState) => state.filters.orderReportProductNameFilter);
  const orderNoFilter = useAppSelector((state: RootState) => state.filters.orderReportOrderNoFilter);
  const awbNoFilter = useAppSelector((state: RootState) => state.filters.orderReportAwbNoFilter);
  const orderReportDelayedShipmentFilter = useAppSelector((state: RootState) => state.filters.orderReportDelayedShipmentFilter);
  const orderReportDelayedDeliveryFilter = useAppSelector((state: RootState) => state.filters.orderReportDelayedDeliveryFilter);
  const orderReportFbvEnabledFilter = useAppSelector((state: RootState) => state.filters.orderReportFbvEnabledFilter);
  const orderReportFbvWarehouseFilter = useAppSelector((state: RootState) => state.filters.orderReportFbvWarehouseFilter)

  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(false);
  const [showProgressDrawer, setShowProgressDrawer] = React.useState(false);
  const [trackingProgress, setTrackingProgress] = React.useState<{label: string, value: string}[]>([]);
  
  const warehouseOptions = useAppSelector((state) => (state.filters.fbvWarehouseFilter || []).map((c) => ({ label: c.name, value: c.name })));

  const handleSort = (column: keyof ShippingData) => {
    const direction = column === orderTrackingData.sortedColumn && orderTrackingData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href,
        page_title: PAGE_TITLE
      }
    );
  }, []);

  const appliedSearchFilters: AppliedFilterType[] = [
    ...(!!productIdFilter ? [{id: 'productId', label: 'Product ID', value: productIdFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!productNameFilter ? [{id: 'productName', label: 'Product Name', value: productNameFilter, type: 'search'}] : []) as AppliedFilterType[] ,
    ...(!!orderNoFilter ? [{id: 'orderNumber', label: 'Order Number', value: orderNoFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!awbNoFilter ? [{id: 'awbNumber', label: 'AWB Number', value: awbNoFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!statusFilter ?[{id: 'orderStatus', label: 'Order Status', value: statusFilter, type: 'select', options: orderStatusOptions}] : []) as AppliedFilterType[],
    ...(!!paymentTypeFilter ?[{id: 'paymentType', label: 'Payment Type', value: paymentTypeFilter, type: 'select', options: orderPaymentOptions}] : []) as AppliedFilterType[],
    ...(!!orderReportFbvEnabledFilter ?[{id: 'fbvEnabled', label: 'FBV', value: orderReportFbvEnabledFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!orderReportDelayedShipmentFilter ?[{id: 'delayedShipment', label: 'Delayed Shipment', value: orderReportDelayedShipmentFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!orderReportDelayedDeliveryFilter ?[{id: 'delayedDelivery', label: 'Delayed Delivery', value: orderReportDelayedDeliveryFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!orderReportFbvWarehouseFilter.length ? [{id: 'fbvWarehouse', label: 'FBV Warehouse', value: orderReportFbvWarehouseFilter, type: 'multi-select', options: warehouseOptions}] : []) as AppliedFilterType[],
  ];

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(orderTrackingApi({
        startDate: orderReportOrderCreatedAtStartDateFilter,
        endDate: orderReportOrderCreatedAtEndDateFilter,
        status: statusFilter,
        paymentType: paymentTypeFilter,
        productId: productIdFilter,
        productName: productNameFilter,
        orderNo: orderNoFilter,
        awbNo: awbNoFilter,
        delayedShipment: orderReportDelayedShipmentFilter,
        delayedDelivery: orderReportDelayedDeliveryFilter,
        fbvEnabled: orderReportFbvEnabledFilter,
        fbvWarehouse: orderReportFbvWarehouseFilter,
        headers: headers
      })).unwrap();
      setPage(0);
      setLoaderActive(false);
      setFilterApplied(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleStartEndDateSelect = (ranges: DateRangeType) => {
    dispatch(setOrderReportOrderCreatedAtStartDateFilter(ranges.startDate as Date));
    dispatch(setOrderReportOrderCreatedAtEndDateFilter(ranges.endDate as Date));
    setFilterApplied(true);
  };

  const trackFilterEvent = async () => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: ['Ordered At Date Range' , ...appliedSearchFilters.map(f => f.label)]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
    
  }, [dispatch, filterApplied]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(orderTrackingDownloadReportApi({
          startDate: orderReportOrderCreatedAtStartDateFilter,
          endDate: orderReportOrderCreatedAtEndDateFilter,
          status: statusFilter,
          paymentType: paymentTypeFilter,
          productId: productIdFilter,
          productName: productNameFilter,
          orderNo: orderNoFilter,
          awbNo: awbNoFilter,
          delayedShipment: orderReportDelayedShipmentFilter,
          delayedDelivery: orderReportDelayedDeliveryFilter,
          fbvEnabled: orderReportFbvEnabledFilter,
          fbvWarehouse: orderReportFbvWarehouseFilter,
          headers: headers
        }));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              report_name: PAGE_TITLE,
              download_type: 'Report'
            }
          );
        }
      }else{
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if(!fab) button.innerHTML = 'Download';
    }
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'productId': {
        dispatch(setOrderReportProductIdFilter(value as string)); 
        if (value) dispatch(setOrderReportOrderCreatedAtStartDateFilter(new Date('2020-01-01') as Date));
        else dispatch(setOrderReportOrderCreatedAtStartDateFilter(new Date(new Date().setDate(new Date().getDate() - 15))));
        break;
      }
      case 'productName': {
        dispatch(setOrderReportProductNameFilter(value as string)); 
        if (value) dispatch(setOrderReportOrderCreatedAtStartDateFilter(new Date('2020-01-01') as Date));
        else dispatch(setOrderReportOrderCreatedAtStartDateFilter(new Date(new Date().setDate(new Date().getDate() - 15))));
        break;
      }
      case 'orderNumber': {
        dispatch(setOrderReportOrderNoFilter(value as string)); 
        if (value) dispatch(setOrderReportOrderCreatedAtStartDateFilter(new Date('2020-01-01') as Date));
        else dispatch(setOrderReportOrderCreatedAtStartDateFilter(new Date(new Date().setDate(new Date().getDate() - 15))));
        break;
      }
      case 'awbNumber': {
        dispatch(setOrderReportAwbNoFilter(value as string)); 
        if (value) dispatch(setOrderReportOrderCreatedAtStartDateFilter(new Date('2020-01-01') as Date));
        else dispatch(setOrderReportOrderCreatedAtStartDateFilter(new Date(new Date().setDate(new Date().getDate() - 15))));
        break;
      }
      case 'orderStatus' : {
        dispatch(setOrderReportStatusFilter(value as string)); break;
      }
      case 'paymentType' : {
        dispatch(setOrderReportPaymentTypeFilter(value as string)); break;
      }
      case 'fbvEnabled' : {
        dispatch(setOrderReportFbvEnabledFilter(value as boolean)); break;
      }
      case 'delayedShipment' : {
        dispatch(setOrderReportDelayedShipmentFilter(value as boolean)); break;
      }
      case 'delayedDelivery' : {
        dispatch(setOrderReportDelayedDeliveryFilter(value as boolean)); break;
      }
      case 'fbvWarehouse' :{
        dispatch(setOrderReportFbvWarehouseFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'fbvEnabled' || id === 'delayedShipment' || id === 'delayedDelivery') 
      handleFilterValueChange({id, value: false});
    else if(id === 'fbvWarehouse')
      handleFilterValueChange({id, value: []});
    else 
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setOrderReportProductIdFilter(''));
    dispatch(setOrderReportProductNameFilter(''));
    dispatch(setOrderReportOrderNoFilter(''));
    dispatch(setOrderReportAwbNoFilter(''));
    dispatch(setOrderReportStatusFilter(''));
    dispatch(setOrderReportPaymentTypeFilter(''));
    dispatch(setOrderReportFbvEnabledFilter(false));
    dispatch(setOrderReportDelayedShipmentFilter(false));
    dispatch(setOrderReportDelayedDeliveryFilter(false));
    dispatch(setOrderReportOrderCreatedAtStartDateFilter(new Date(new Date().setDate(new Date().getDate() - 15))));
    dispatch(setOrderReportFbvWarehouseFilter([]));
    setFilterApplied(true);
  }

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'delayedDelivery' : {
          dispatch(setOrderReportDelayedDeliveryFilter(value as boolean)); break;
        }
        case 'delayedShipment' : {
          dispatch(setOrderReportDelayedShipmentFilter(value as boolean)); break;
        }
        case 'orderStatus' : {
          dispatch(setOrderReportStatusFilter(value as string)); break;
        }
        case 'paymentType' : {
          dispatch(setOrderReportPaymentTypeFilter(value as string)); break;
        }
        case 'fbvEnabled' : {
          dispatch(setOrderReportFbvEnabledFilter(value as boolean)); break;
        }
        case 'fbvWarehouse' :{
          dispatch(setOrderReportFbvWarehouseFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }

  const showTrackingProgress = (steps: {label: string, value: string}[]) => {
    setTrackingProgress(steps);
    setShowProgressDrawer(true);
  }

  const clearFilters = () => {
    dispatch(resetFilter());
    setFilterApplied(true);
  }

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Order Tracking">
        <DateRangeLabel
          startDate={orderReportOrderCreatedAtStartDateFilter}
          endDate={orderReportOrderCreatedAtEndDateFilter}
          onClick={() => {setShowDateRangeFilter(true)}}
        />
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              <SelectFilter
                label={'Order Status'} 
                value={statusFilter}
                options={orderStatusOptions}
                onChange={(value) => {
                  dispatch(setOrderReportStatusFilter(value));
                  setFilterApplied(true);
                }}
              />
              <SelectFilter
                label={'Payment Type'} 
                value={paymentTypeFilter}
                options={orderPaymentOptions}
                onChange={(value) => {
                  dispatch(setOrderReportPaymentTypeFilter(value));
                  setFilterApplied(true);
                }}
              />
              {fbv && <MultiSelectFilter
                  label={'FBV Warehouse'}
                  values={orderReportFbvWarehouseFilter}
                  options={warehouseOptions}
                  allowAll={true}
                  onSubmit={(val) => {
                    dispatch(setOrderReportFbvWarehouseFilter(val));
                    setFilterApplied(true);
                  }}
                />
              }
              <CheckboxFilter
                checked={orderReportDelayedShipmentFilter} 
                label={<p className={styles.checkboxTxt}>Delayed Shipment</p>}
                onChange={(val) => {
                  dispatch(setOrderReportDelayedShipmentFilter(val));
                  setFilterApplied(true);
                }}
              />
              <CheckboxFilter
                checked={orderReportDelayedDeliveryFilter}
                label={<p className={styles.checkboxTxt}>Delayed Delivery</p>}
                onChange={(val) => {
                  dispatch(setOrderReportDelayedDeliveryFilter(val));
                  setFilterApplied(true);
                }}
              />
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          selectFilters={[
            {
              id: 'orderStatus',
              label: 'Order Status',
              type: 'select',
              value: statusFilter,
              options: orderStatusOptions,
            },
            {
              id: 'paymentType',
              label: 'Payment Type',
              type: 'select',
              value: paymentTypeFilter,
              options: orderPaymentOptions,
            },
          ]}
        checkboxFilters={[
          {
            id: 'delayedDelivery',
            label: 'Delayed Delivery',
            type: 'checkbox',
            value: orderReportDelayedDeliveryFilter,
          },
          {
            id: 'delayedShipment',
            label: 'Delayed Shipment',
            type: 'checkbox',
            value: orderReportDelayedShipmentFilter,
          }
        ]}
        multiSelectFilters={[
          ...(fbv ? [
            {
              id: 'fbvWarehouse',
              label: 'FBV Warehouse',
              type: 'multiSelect' as 'multiSelect',
              value: orderReportFbvWarehouseFilter,
              options: warehouseOptions,
            }
          ] : [])
        ]}
      />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<ShippingData>
          data={orderTrackingData?.orderTrackingData?.shipping_data || []}
          columns={orderTrackingColumns({statusFilter, showTrackingProgress, isMobile})}
          sortedColumn={orderTrackingData.sortedColumn}
          handleSort={handleSort}
          sortDirection={orderTrackingData.sortDirection}
          showPagination
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
          fixedColumn={isMobile ? fixedColumn: undefined}
        />
      )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={orderReportOrderCreatedAtStartDateFilter}
        endDate={orderReportOrderCreatedAtEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
      />

      <MultiStepProgressDrawer
        steps={trackingProgress}
        open={showProgressDrawer}
        onOpen={() => setShowProgressDrawer(true)}
        onClose={() => setShowProgressDrawer(false)}
      />
    </Box>
  );
};

export default OrderTracking;
