export const BACKEND_BASE_URL = "https://api.vaaree.com";

export const ROUTES = {
  DASHBOARD: "/",
  ORDER_TRACKING: "/order-tracking",
  SOH_REPORT: "/soh-report",
  SALES_REPORT: "/sales-report",
  LOR_REPORT: "/lor-report",
  RETURNS: "/return-exchange-report/returns",
  EXCHANGES: "/return-exchange-report/exchanges",
  RTO: "/rto-report",
  PAYMENT: "/payment-report",
  REPORT_LIST: "/reports",
  GSTR_REPORT: '/gstr-report',
  FBV_STORAGE_CHARGES_REPORT: '/fbv-storage-charges-report',
  ISSUE_TRACKING: '/issue-tracking',
  PRODUCT_CATALOGUE: '/product-catalogue',
  REQUEST_TRACKING: '/request-tracking',
  MRP_LABEL: '/mrp-label'
}

export const ROUTE_REPORT_MAP = {
  [ROUTES.ORDER_TRACKING]: 'Order Tracking',
  [ROUTES.SOH_REPORT]: 'SOH Report',
  [ROUTES.SALES_REPORT]: 'Sales Report',
  [ROUTES.LOR_REPORT]: 'LOR Report',
  [ROUTES.RETURNS]: 'Returns Report',
  [ROUTES.EXCHANGES]: 'Exchanges Report',
  [ROUTES.RTO]: 'RTO Report',
  [ROUTES.PAYMENT]: 'Payments Report',
  [ROUTES.GSTR_REPORT]: 'GSTR Report',
  [ROUTES.FBV_STORAGE_CHARGES_REPORT]: 'FBV Storage Charges Report',
  [ROUTES.ISSUE_TRACKING]: 'Issue Tracking',
  [ROUTES.REQUEST_TRACKING]: 'Requests Tracking',
  [ROUTES.MRP_LABEL]: 'MRP Label'
}

export type DateRangeType = {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export const DEFAULT_YELLOW_COLOR = '#FFC845';
export const DEFAULT_BLACK_COLOR = '#211913';