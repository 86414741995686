import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {Box, Button, Fab, useMediaQuery, useTheme} from '@mui/material';
import { RootState } from '../../store';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme styles
import styles from './SalesReport.module.scss';
import { setSort, salesReportApi, salesReportDownloadReportApi, SalesReportDataHash } from "../../slices/salesReportSlice";
import FbvIcon from '../../images/fbv-icon.png';
import Loader from "components/atoms/Loader/Loader";
import {  setSalesReportCategoryFilter, setSalesReportProductIdFilter , setSalesReportProductTitleFilter, setSalesReportFbvEnabledFilter, setSalesReportOrderCreatedStartDateFilter, setSalesReportOrderCreatedEndDateFilter, setSalesReportFbvWarehouseFilter, resetFilter} from "../../slices/filtersSlice";
import { MIXPANEL_EVENT_TYPES, triggerMixpanelEvent } from "../../hooks/mixpanel_hook";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import { fixedColumn, salesReportColumns } from "./SalesReport.constant";
import { downloadFile } from "../../helpers/utils";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import DateRangeSelector, { DateRangeLabel } from "components/molecules/DateRangeSelector/DateRangeSelector";
import SearchByFilter, { AppliedFilterType, SearchSelections } from "components/molecules/SearchByFilter/SearchByFilter";
import SelectFilter from "components/molecules/SelectFilter/SelectFilter";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SwipeableFilters from "components/organisms/SwipeableFilter/SwipeableFilter";
import ColorButton from "components/atoms/ColorButton/ColorButton";
import MultiSelectFilter from 'components/molecules/MultiSelectFilter/MultiSelectFilter';


const searchByOptions = [
  {id: 'productId', label: 'Product ID'},
  {id: 'productName', label: 'Product Name'},
]

const PAGE_TITLE = 'Sales Report'

const SalesReport = () => {

  const fbv = useAppSelector((state) => state.auth.basicUserInfo?.fbv || false);

  const dispatch = useAppDispatch();
  const [showDateRangeFilter, setShowDateRangeFilter] = React.useState(false);
  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [filterOpen, setFilterOpen] = useState(false);

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo) {
      const token = JSON.parse(userInfo).token
      const headers = token ? { Authorization: `${token}` } : undefined;
      await dispatch(salesReportApi({startDate: salesReportOrderCreatedStartDateFilter, endDate: salesReportOrderCreatedEndDateFilter, category: categoryFilter, productId: textFilter, productTitle: productTitleFilter, headers: headers, fbvEnabled: salesReportFbvEnabledFilter, fbvWarehouse: salesReportFbvWarehouseFilter})).unwrap();
      setPage(0);
      setLoaderActive(false);
      setFilterApplied(false);
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  };

  const salesReportData = useAppSelector((state) => state.salesReport);

  const handleSort = (column: keyof SalesReportDataHash) => {
    const direction = column === salesReportData.sortedColumn && salesReportData.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ column, direction }));
  };

  useEffect(() => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.PAGE_VISIT, 
      {
        page_link: window.location.href, 
        page_title: PAGE_TITLE
      }
    );
  }, []);

  useEffect(() => {
    if (categoryFilter !== ''){
      const av_categories = salesReportData.salesReportData?.l1_categories || [];
      if (!av_categories.includes(categoryFilter)){
        setSalesReportCategoryFilter('');
      }
    }
  }, [salesReportData.salesReportData?.l1_categories]);

  const downloadReport = async (fab?: boolean) => {
    const button = document.getElementById('download-button');
    if (button) {
      if(!fab) button.innerHTML = 'Downloading...';
      const userInfo = localStorage.getItem('userInfo');
      if(userInfo) {
        const token = JSON.parse(userInfo).token
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(salesReportDownloadReportApi({startDate: salesReportOrderCreatedStartDateFilter, endDate: salesReportOrderCreatedEndDateFilter, category: categoryFilter, productId: textFilter, productTitle: productTitleFilter, headers: headers, fbvEnabled: salesReportFbvEnabledFilter, fbvWarehouse: salesReportFbvWarehouseFilter}));
        const success = await downloadFile({downloadApi, fileName: 'report.csv', fileType: 'text/csv'})
        if(success){
          triggerMixpanelEvent(
            MIXPANEL_EVENT_TYPES.DOWNLOAD,
            {
              report_name: PAGE_TITLE,
              download_type: 'Report'
            }
          );
        }
      }else{
        dispatch(resetAuth());
        dispatch(resetFilter());
      }
      if(!fab) button.innerHTML = 'Download';
    }
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const categoryFilter = useAppSelector((state: RootState) => state.filters.salesReportCategoryFilter);
  const textFilter = useAppSelector((state: RootState) => state.filters.salesReportProductIdFilter);
  const salesReportOrderCreatedStartDateFilter = useAppSelector((state: RootState) => state.filters.salesReportOrderCreatedStartDateFilter);
  const salesReportOrderCreatedEndDateFilter = useAppSelector((state: RootState) => state.filters.salesReportOrderCreatedEndDateFilter);
  const productTitleFilter = useAppSelector((state: RootState) => state.filters.salesReportProductTitleFilter);
  const salesReportFbvEnabledFilter = useAppSelector((state: RootState) => state.filters.salesReportFbvEnabledFilter);
  const salesReportFbvWarehouseFilter = useAppSelector((state: RootState) => state.filters.salesReportFbvWarehouseFilter);

  let availableCategories = salesReportData.salesReportData?.l1_categories.map(val => ({label: val, value: val})) || [];
  availableCategories = [...availableCategories, {label: 'All', value: ''}];
  availableCategories.reverse();

  const warehouseOptions = useAppSelector((state) => (state.filters.fbvWarehouseFilter || []).map((c) => ({ label: c.name, value: c.name })));
  
  const appliedSearchFilters:AppliedFilterType[] = [
    ...(!!textFilter ? [{id: 'productId', label: 'Product ID', value: textFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!productTitleFilter ? [{id: 'productName', label: 'Product Name', value: productTitleFilter, type: 'search'}] : []) as AppliedFilterType[],
    ...(!!categoryFilter ? [{id: 'category', label: 'Category', value: categoryFilter, type: 'select', options: availableCategories}] : []) as AppliedFilterType[],
    ...(!!salesReportFbvEnabledFilter ? [{id: 'fbvEnabled', label: 'FBV', value: salesReportFbvEnabledFilter, type: 'checkbox'}] : []) as AppliedFilterType[],
    ...(!!salesReportFbvWarehouseFilter.length ? [{id: 'fbvWarehouse', label: 'FBV Warehouse', value: salesReportFbvWarehouseFilter, type: 'multi-select', options: warehouseOptions}] : []) as AppliedFilterType[],
  ];

  const handleStartEndDateSelect = (ranges: any) => {
    dispatch(setSalesReportOrderCreatedStartDateFilter(ranges.startDate as Date));
    dispatch(setSalesReportOrderCreatedEndDateFilter(ranges.endDate as Date));
    setFilterApplied(true);
  };

  const trackFilterEvent = async () => {
    triggerMixpanelEvent(
      MIXPANEL_EVENT_TYPES.REPORT_INTERACTION,
      {
        report_name: PAGE_TITLE,
        filter: ['Date Range' , ...appliedSearchFilters.map(f => f.label)]
      }
    )
  }

  useEffect(() => {
    if(filterApplied){
      trackFilterEvent();
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const clearFilters = () => {
    dispatch(resetFilter());
    setFilterApplied(true);
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };


  const handleFilterValueChange = ({id, value}: {id: string, value: string | boolean | string[]}) => {
    switch(id){
      case 'productId': {
        dispatch(setSalesReportProductIdFilter(value as string)); break;
      }
      case 'productName': {
        dispatch(setSalesReportProductTitleFilter(value as string)); break;
      }
      case 'category': {
        dispatch(setSalesReportCategoryFilter(value as string)); break;
      }
      case 'fbvEnabled': {
        dispatch(setSalesReportFbvEnabledFilter(value as boolean)); break;
      }
      case 'fbvWarehouse' :{
        dispatch(setSalesReportFbvWarehouseFilter(value as string[])); break;
      }
    }
    setFilterApplied(true);
  }

  const onSearchByClear = (id: string) => {
    if(id === 'fbvEnabled')
      handleFilterValueChange({id, value: false});
    else if(id === 'fbvWarehouse')
      handleFilterValueChange({id, value: []});
    else 
      handleFilterValueChange({id, value: ''});
  }

  const onSearchByAllClear = () => {
    dispatch(setSalesReportProductIdFilter(''));
    dispatch(setSalesReportProductTitleFilter(''));
    dispatch(setSalesReportCategoryFilter(''));
    dispatch(setSalesReportFbvEnabledFilter(false));
    dispatch(setSalesReportFbvWarehouseFilter([]));
    setFilterApplied(true);
  }

  const handleSwipeableDrawerFilters = (idValueMap: Record<string, (string | boolean| string[])>) => {
    for(const [key, value] of Object.entries(idValueMap)){
      switch(key){
        case 'category' : {
          dispatch(setSalesReportCategoryFilter(value as string)); break;
        }
        case 'fbvEnabled' : {
          dispatch(setSalesReportFbvEnabledFilter(value as boolean)); break;
        }
        case 'fbvWarehouse' :{
          dispatch(setSalesReportFbvWarehouseFilter(value as string[])); break;
        }
      }
    }
    setFilterApplied(true);
  }


  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label="Sales Report">
        <DateRangeLabel
          startDate={salesReportOrderCreatedStartDateFilter}
          endDate={salesReportOrderCreatedEndDateFilter}
          onClick={() => {setShowDateRangeFilter(true)}}
        />
      </MainHeader>

      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />

          {!isMobile? (
            <>
              <SelectFilter
                label={'Category'} 
                value={categoryFilter}
                options={availableCategories}
                onChange={(value) => {
                  dispatch(setSalesReportCategoryFilter(value));
                  setFilterApplied(true);
                }}
              />
              {fbv && <MultiSelectFilter
                  label={'FBV Warehouse'}
                  values={salesReportFbvWarehouseFilter}
                  options={warehouseOptions}
                  allowAll={true}
                  onSubmit={(val) => {
                    dispatch(setSalesReportFbvWarehouseFilter(val));
                    setFilterApplied(true);
                  }}
                />
              }
            </>
          ): (
            <Fab variant="extended" size="small" className={styles.filterFAB} onClick={() => setFilterOpen(true)}>
              <FilterAltOutlinedIcon fontSize="small" />
              Filter
            </Fab>
          )}
        </Box>
        {!isMobile ? (
          <Button
            id="download-button"
            className={styles.downloadBtn}
            onClick={() => downloadReport()}
          >
            Download
          </Button>
        ):(
          <Fab className={styles.downloadFAB} onClick={() => downloadReport(true)} size='medium' id="download-button">
            <FileDownloadOutlinedIcon fontSize='small' />
          </Fab>
        )}
      </Box>
        <SwipeableFilters
          open={filterOpen}
          onOpen={() => setFilterOpen(true)}
          onClose={() => setFilterOpen(false)}
          onAction={handleSwipeableDrawerFilters}
          selectFilters={[
            {
              id: 'category',
              label: 'Category',
              type: 'select',
              value: categoryFilter,
              options: availableCategories,
            },
          ]}
          multiSelectFilters={[
            ...(fbv ? [
              {
                id: 'fbvWarehouse',
                label: 'FBV Warehouse',
                type: 'multiSelect' as 'multiSelect',
                value: salesReportFbvWarehouseFilter,
                options: warehouseOptions,
              }
            ] : [])
          ]}
        />
      {appliedSearchFilters.length ? (
        <SearchSelections
          appliedFilters={appliedSearchFilters} 
          allClear={onSearchByAllClear} 
          onClear={onSearchByClear}
        />
      ): null}

      <Loader show={loaderActive} />

      {!loaderActive && (
          <TableManager<SalesReportDataHash>
            data={salesReportData?.salesReportData?.sales_report_data || []}
            columns={salesReportColumns()}
            sortedColumn={salesReportData.sortedColumn}
            handleSort={handleSort}
            sortDirection={salesReportData.sortDirection}
            showPagination
            currentPage={page}
            rowPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
            fixedColumn={isMobile ? fixedColumn: undefined}
          />
        )}

      <DateRangeSelector
        open={showDateRangeFilter}
        onClose={() => {setShowDateRangeFilter(false)}}
        startDate={salesReportOrderCreatedStartDateFilter}
        endDate={salesReportOrderCreatedEndDateFilter}
        updateDateRange={handleStartEndDateSelect}
        header={'Select Date Range'}
        onOpen={() => {setShowDateRangeFilter(true)}}
      />
      

    </Box>
  );

};
  
export default SalesReport;
