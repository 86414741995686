import { useState, useRef, useEffect } from "react";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import { Box, TextField, useTheme, useMediaQuery, SwipeableDrawer, } from "@mui/material";
import styles from "./MRPLabelModal.module.scss";
import ModalBox from "components/atoms/ModalBox/ModalBox";
import ColorButton from "components/atoms/ColorButton/ColorButton";
import { NotificationType, showNotification } from "slices/notificationSlice";

type MRPLabelModalPropType = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  onSubmit: (file: File[]) => void;
}

type FormStateType = {
  skuid: string;
  mrp: number;
  weight: number;
  dimensions: string;
  material: string;
  includes: string;
}

const initialFormState:FormStateType = {
  skuid: '',
  mrp: 0,
  weight: 0,
  dimensions: '',
  material: '',
  includes: ''
};


type FormContentPropType = {
  isMobile: boolean;
  formData: FormStateType;
  handleChange: (e: any) => void;
  onClose: () => void;
  handleSubmit: (e: React.FormEvent) => void;
}

const FormContent:React.FC<FormContentPropType> = (props: FormContentPropType) => {
  const {
    isMobile,
    formData,
    handleChange,
    onClose,
    handleSubmit,
  } = props;

  return (
    <div className={isMobile ? styles['swipeable-mobile-drawer']: undefined}>
        <div className={styles['get-help-header']}>
          Generate Single MRP Label
        </div>
        <Box component="form" className={styles['gethelp-form']}>
          <TextField fullWidth required name="skuid" onChange={handleChange} id="outlined-skuid" label="SKU Id" className={styles['txt-input']} value={formData.skuid}  />
          <TextField fullWidth required name="mrp" onChange={handleChange} id="outlined-mrp" label="MRP" className={styles['txt-input']} value={formData.mrp}  type="number" inputProps={{ step: "0.01" }}/>
          <TextField fullWidth required name="weight" onChange={handleChange} id="outlined-weight" label="Weight (in grams)" className={styles['txt-input']} value={formData.weight}  type="number" />
          <TextField fullWidth required name="dimensions" onChange={handleChange} id="outlined-dimensions" label="Dimensions (with units)" className={styles['txt-input']} value={formData.dimensions} placeholder='30cm*24cm*9cm'/>
          <TextField fullWidth required name="includes" onChange={handleChange} id="outlined-includes" label="Includes" className={styles['txt-input']} value={formData.includes} />
          <TextField fullWidth required name="material" onChange={handleChange} id="outlined-material" label="Material" className={styles['txt-input']} value={formData.material} />
        </Box>

        <div className={styles['get-help-footer']}>
          <ColorButton bgColor="#FFF" className={styles['discard-button']} variant="outlined" onClick={onClose}>
            Discard
          </ColorButton>
          <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={handleSubmit} disabled={!formData.skuid || !formData.mrp || !formData.weight || !formData.dimensions || !formData.material || !formData.includes}>
            Submit
          </ColorButton>
        </div>
      </div>
  );
}


const MRPLabelModal = (props: MRPLabelModalPropType) => {
  const dispatch = useAppDispatch();
  const {open, onClose, onOpen, onSubmit} = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState<FormStateType>(initialFormState);

  useEffect(() => {
    if(open){
      setFormData(initialFormState);
    }
  }, [open]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formDataToCSVFile = (): File => {
    const csvHeaders = Object.keys(formData).join(','); 
    const csvValues = Object.values(formData).join(','); 

    const csvContent = `${csvHeaders}\n${csvValues}`; 
    const blob = new Blob([csvContent], { type: 'text/csv' }); 
    return new File([blob], 'form-data.csv', { type: 'text/csv' }); 
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  
    // Validation checks
    const { skuid, mrp, weight, dimensions, material, includes } = formData;
    const errors: string[] = [];
    const skuRegex = /^[a-zA-Z0-9_.-]+$/;
    if (!skuRegex.test(skuid)) {
      errors.push("SKU Id must not contain spaces or special characters except _ . -.");
    }
    if (!skuid.trim()) {
      errors.push("SKU Id cannot be empty.");
    }
    if (mrp <= 0) {
      errors.push("MRP must be greater than 0");
    }
    if (weight <= 0) {
      errors.push("Weight must be greater than 0");
    }
    if (!material.trim()) {
      errors.push("Material field cannot be empty.");
    }
    if (!includes.trim()) {
      errors.push("Includes field cannot be empty.");
    }

    // Dimensions Validation
    const dimensionsRegex = /(\d+(\.\d+)?)(cm|mm|m)/gi;
    const formattedDimensions = dimensions.replace(/\s*x\s*/gi, '*');

    // Extract all valid dimension units
    const matches = [...formattedDimensions.matchAll(dimensionsRegex)];

    // Check if all dimensions are properly formatted
    if (matches.length !== 3) {
      errors.push("Dimensions must follow the format 'l*b*h' with units (cm, mm, m) required.");
    } else {
      // Extract units and check for consistency
      const units = matches.map((match) => match[3].toLowerCase());
      const uniqueUnits = new Set(units);

      if (uniqueUnits.size > 1) {
        errors.push("Inconsistent units: All dimensions must use the same unit (either cm, mm, or m).");
      }
    }

    if (errors.length > 0) {
      errors.forEach((error) => {
        dispatch(showNotification({ message: error, type: NotificationType.Error }));
      });
      return; 
    }
    
    const csvFile = formDataToCSVFile(); 
    onSubmit([csvFile]); 
    onClose();
  };
  

  const handleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    open ? onOpen() : onClose();
  };


  if(isMobile){
    return (
      <SwipeableDrawer
        anchor={"bottom"}
        open={open}
        onClose={handleDrawer(false)}
        onOpen={handleDrawer(true)}
        className={styles['drawer']}
      >
        <FormContent
          isMobile={isMobile}
          formData={formData}
          handleChange={handleChange}
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      </SwipeableDrawer>
    );
  }

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      className={styles['get-help-modal']}
    >
      <FormContent
        isMobile={isMobile}
        formData={formData}
        handleChange={handleChange}
        onClose={onClose}
        handleSubmit={handleSubmit} />
    </ModalBox>
  );
}

export default MRPLabelModal;
